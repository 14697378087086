// Array of sections
import home from './home'
import center from './center'
import lineapi from './lineapi'
import booking from './booking'
import master from './master'
import report from './report'

// Array of sections
export default [
  ...home,
  ...center,
  ...lineapi,
  ...booking,
  ...master,
  ...report,
]
