export default [
  {
    header: 'Smart Booking',
    icon: 'CalendarIcon',
    action: 'default',
    children: [
      {
        title: 'TitleBooking.Calendar',
        route: 'booking-calendar',
        icon: 'CalendarIcon',
        resource: 'ModuleBooking.Calendar',
        action: 'read',
      },
      {
        title: 'TitleBooking.Chat',
        route: 'booking-chat',
        icon: 'MessageSquareIcon',
        resource: 'ModuleBooking.Chat',
        action: 'read',
      },
      {
        title: 'TitleBooking.Booking',
        route: 'booking-booking-list',
        icon: 'CalendarIcon',
        resource: 'ModuleBooking.Booking',
        action: 'read',
      },
      {
        title: 'TitleBooking.Renting',
        route: 'booking-renting-list',
        icon: 'MapPinIcon',
        resource: 'ModuleBooking.Renting',
        action: 'read',
      },
      {
        title: 'TitleBooking.Payment',
        route: 'booking-payment-list',
        icon: 'DollarSignIcon',
        resource: 'ModuleBooking.Payment',
        action: 'read',
      },
      {
        title: 'TitleBooking.PaymentType',
        route: 'booking-payment-type-list',
        icon: 'CreditCardIcon',
        resource: 'ModuleBooking.PaymentType',
        action: 'read',
      },
      {
        title: 'TitleBooking.Role',
        route: 'booking-role-list',
        icon: 'AwardIcon',
        resource: 'ModuleBooking.Role',
        action: 'read',
      },
      {
        title: 'TitleBooking.EmailTemplate',
        route: 'booking-email-template-list',
        icon: 'MailIcon',
        resource: 'ModuleBooking.EmailTemplate',
        action: 'read',
      },
      {
        title: 'TitleBooking.NotificationTemplate',
        route: 'booking-notification-template-list',
        icon: 'BellIcon',
        resource: 'ModuleBooking.NotificationTemplate',
        action: 'read',
      },
      {
        title: 'TitleBooking.LineNotifyTemplate',
        route: 'booking-line-notify-template-list',
        icon: 'MessageSquareIcon',
        resource: 'ModuleBooking.LineNotifyTemplate',
        action: 'read',
      },
      {
        title: 'TitleBooking.Contract',
        route: 'booking-contract-list',
        icon: 'PenToolIcon',
        resource: 'ModuleBooking.Contract',
        action: 'read',
      },
      {
        title: 'TitleBooking.ContractType',
        route: 'booking-contract-type-list',
        icon: 'CodepenIcon',
        resource: 'ModuleBooking.ContractType',
        action: 'read',
      },
      {
        title: 'TitleBooking.Satisfaction',
        route: 'booking-satisfaction-list',
        icon: 'AwardIcon',
        resource: 'ModuleBooking.Satisfaction',
        action: 'read',
      },
      {
        title: 'TitleBooking.LineNotify',
        route: 'booking-line-notify-list',
        icon: 'BellIcon',
        resource: 'ModuleBooking.LineNotify',
        action: 'read',
      },
      {
        title: 'TitleBooking.SatisfactionTransaction',
        route: 'booking-satisfaction-transaction-list',
        icon: 'SlidersIcon',
        resource: 'ModuleBooking.SatisfactionTransaction',
        action: 'read',
      },
    ],
  },
]
