export default [
  {
    header: 'LINE API',
    icon: 'SmartphoneIcon',
    action: 'default',
    children: [
      {
        title: 'Line User',
        route: 'lineapi-lineuser-list',
        icon: 'AtSignIcon',
        resource: 'LineUser',
        action: 'read',
      },
      {
        title: 'Line Liff',
        route: 'lineapi-lineliff-list',
        icon: 'TrelloIcon',
        resource: 'LineLiff',
        action: 'read',
      },
      {
        title: 'Rich Menu',
        route: 'lineapi-richmenu-list',
        icon: 'GridIcon',
        resource: 'RichMenu',
        action: 'read',
      },
      {
        title: 'Media',
        route: 'lineapi-media',
        icon: 'CameraIcon',
        resource: 'Media',
        action: 'read',
      },
      {
        title: 'Chat Message',
        route: 'lineapi-chatmessage-list',
        icon: 'MessageSquareIcon',
        resource: 'Chatmessage',
        action: 'read',
      },
      {
        title: 'Reply Message',
        route: 'lineapi-replymessage-list',
        icon: 'MessageCircleIcon',
        resource: 'Replymessage',
        action: 'read',
      },
      {
        title: 'Dialogue',
        route: 'lineapi-dialogue-list',
        icon: 'ColumnsIcon',
        resource: 'Dialogue',
        action: 'read',
      },
      {
        title: 'Audience',
        route: 'lineapi-audience-list',
        icon: 'CrosshairIcon',
        resource: 'Audience',
        action: 'read',
      },
      {
        title: 'Multicast',
        route: 'lineapi-multicast',
        icon: 'WindIcon',
        resource: 'Multicast',
        action: 'read',
      },
      {
        title: 'Share Target',
        route: 'lineapi-sharetarget-list',
        icon: 'Share2Icon',
        resource: 'ShareTarget',
        action: 'read',
      },
      {
        title: 'Share Target Transaction',
        route: 'lineapi-sharetarget-transaction-list',
        icon: 'ActivityIcon',
        resource: 'ShareTargetTransaction',
        action: 'read',
      },
      {
        title: 'Form Liff',
        route: 'lineapi-formliff-list',
        icon: 'ServerIcon',
        resource: 'FormLiff',
        action: 'read',
      },
    ],
  },
]
