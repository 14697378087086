<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <td
            class="brand-logo"
            style="vertical-align: middle;"
          >
            <logo
              style="height: 30px; width: auto;margin-top: 0rem;"
            />
          </td>
          <td style="vertical-align: middle;">
            <h2
              class="brand-text mb-0"
              style="margin-left: 0.4rem; padding-left: 0px;"
            >
              {{ appName }}
            </h2>
          </td>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import Logo from '@/@core/layouts/components/Logo.vue'
import store from '@/store'

export default {
  components: {
    BLink,
    Logo,
  },
  data() {
    return {
      skin: store.state.appConfig.layout.skin,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style>

</style>
